<template>
  <ChartLine
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import axios from 'axios'
import { Line as ChartLine } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'LineChart',
  components: { ChartLine },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
  },
  data: () => ({
        usrData: {},
        usrToken: '',
        chartData: {
            labels: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June'
            ],
            datasets: [ 
                { 
                    data: [0, 0, 0, 0, 0, 0],
                    label: 'loading',
                    //backgroundColor: '#f87979'
                }
            ]
        },
        chartOptions: {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
               /*  title: {
                    display: true,
                    text: 'Custom Chart Title'
                } */
            }
        }
  }),

  methods: {
    async getDataChartFondos() {
        //Obtener los datos del Balance de Fondos
        await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/grafica`,
            params: {
                tx_agno: 2022,
                tx_mes: 8
            },
            headers: { 
                "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken,
                //"Accept": "application/json"
            }
        })
        .then(async response => {
            //this.loading = false
            let respGrafFondos = response.data
            if (!respGrafFondos.success){
                console.error('Balance Error: ', respGrafFondos)
            }
            else {
                var dataLabels = []
                var dataSets = [
                    { 
                        data: [],
                        borderColor: '#745af2',
                        tension: 0.3
                    }]
                for (var dia in respGrafFondos.data) {
                    console.log('For: ', dia, respGrafFondos.data[dia])
                    if (respGrafFondos.data[dia]) {
                        dataLabels.push(dia.substring(8))
                        dataSets[0].data.push(respGrafFondos.data[dia].balance)
                    }
                }
                this.chartData = {
                    labels: dataLabels,
                    datasets: dataSets
                }
            }
        })
        .catch(error => {
            console.error('Balance Error: ', error)
            this.loading = false;
            //let message = !error.response ? error.message : error.response.data.msg;
        })
    }
  },

  async created() {
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    this.usrToken = localStorage.getItem("usrToken")
    this.getDataChartFondos()
  }

}
</script>