<template>
  <!-- Pricing Start -->
  <section class="section" id="pricing">
    <div class="container">
      <div class="row">
        <div class="col-lg-4" v-for="plan in listPlanes" :key="plan.id">
          <div class="pricing-box rounded text-center p-4">
            <div class="pricing-icon-bg my-4">
              <i class="mdi mdi-account h1"></i>
            </div>
            <h3 class="font-weight-medium pb-4">{{plan.nb_plan}}</h3>

            <h2 class="font-weight-bold pb-2">
              <b>
                <sup class="h4 mr-2 font-weight-bold">$</sup>{{`${plan.va_inversion_min}`}} - <sup class="h4 mr-2 font-weight-bold">$</sup>{{plan.va_inversion_max}}
              </b>
            </h2>
            <!-- <p class="text-muted font-weight-semibold">User / Month</p> -->
            <h5 class="pricing-item mb-0 pt-3">Rendimiento: {{plan.va_diario}}% x día</h5>
            <div class="text-muted pb-4">Cada {{plan.va_horas}} horas beneficio: <b>{{plan.va_horas_profit}}%</b></div>
            <div class="text-left pt-3">
            <h6 class="subtitle-2 mb-0">Beneficio Total</h6>
            <div class="subtitle-2"><b>{{plan.va_beneficio}}%</b></div>
            </div>
            <div class="text-left pt-3 pb-5">
            <h6 class="subtitle-2 mb-0">Plazo de Inversión</h6>
            <div class="subtitle-2"><b>{{plan.va_plazo}}%</b></div>
            </div>
            <!-- <p class="text-muted font-weight-semibold pt-3" style="margin-bottom: 0.5rem;">Referidos</p>
            <ul class="text-left mb-3">
              <li class="text-muted">Nivel 1: 7%</li>
              <li class="text-muted">Nivel 2: 5%</li>
              <li class="text-muted">Nivel 3: 3%</li>
            </ul>  -->           
            <button type="button" @click="$router.push('/pages/invertir')" class="btn btn-rounded btn-outline-success text-success" v-if="btnVisible"> Invertir Ahora </button>
            <div style="margin-top: -10px !important;">
              <div class="hero-bottom-img">
                <img
                  src="@/assets/images/pricing-bottom-bg.png"
                  alt
                  class="img-fluid d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Pricing End -->
</template>

<script>
import axios from 'axios'

export default {
    name: "Pricing",

    props: {
        btnVisible: {
        type: Boolean,
        default: true,
        }
    },
    data: () => ({
    usrToken: '',
    usrData: {},
    listPlanes: [],
    configApp: {}
    }),

  methods: {
    formatMontosPlanes(planes) {
      return planes.map((plan) => {
                plan.va_horas_profit = plan.va_horas_profit.replace('.',',').toLocaleString("es-VE");
                plan.va_inversion_min = plan.va_inversion_min.toLocaleString("es-VE");
                plan.va_inversion_max = plan.va_inversion_max.toLocaleString("es-VE");
                if(plan.id === 1){
                    //this.configApp.va_inversion_max = plan.va_inversion_max
                    this.configApp.va_inversion_min = Number(plan.va_inversion_min)
                    localStorage.setItem('vars', JSON.stringify(this.configApp))
                }

                return plan
            });
    },
  },
  computed: {
  },

  async created () {
    this.usrToken = localStorage.getItem("usrToken")
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    this.configApp = await JSON.parse(localStorage.getItem("vars"))
    //Obtener la informacion de los planes
    axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/api/planes`,
        headers: { 
            "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
            //"Accept": "application/json"
        }
    })
    .then(async response => {
        //this.loading = false
        let respPlanes = response.data
        //console.log('Withdrawals...', respBalance);
        if (!respPlanes.success){
            console.error('respPlanes Error: ', respPlanes)
        }
        else {
            this.listPlanes = this.formatMontosPlanes(respPlanes.data)
        }
    })
    .catch(error => {
        console.error('respPlanes Error: ', error)
        this.loading = false;
        //let message = !error.response ? error.message : error.response.data.msg;
    })
  },
}
</script>
<style>
.pricing-box {
    background: #fff;
    box-shadow: 0 3px 12px rgba(2, 87, 63, 0.06);
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
}
.pricing-box .pricing-icon-bg {
    background: #e8fdeb;
    color: #00ccc0;
    /*color: rgba(6, 215, 156,1);*/
    display: block;
    height: 80px;
    width: 80px;
    line-height: 100px;
    margin: 0 auto;
    border-radius: 20% 20% 4% 20% / 20% 20% 4% 20%;
    box-shadow: 0 0 0 7px rgb(90 79 220 / 3%);
    transition: all 0.5s;
}
.pricing-box:hover .pricing-icon-bg, .pricing-box.active .pricing-icon-bg {
    background-color: #00ccc0;
    box-shadow: 0 0 0 7px rgb(90 79 220 / 6%);
    color: #fff;
    border-radius: 4% 20% 20% 20% / 4% 20% 20% 20%;
}

.pricing-box .pricing-item li {
    padding: 10px 0;
}

.pricing-box .pr-btn {
    position: relative;
    z-index: 1;
}

.btn-outline-success:hover{background-color:#28a745;border-color:#28a745;color:#fff!important}
</style>