<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
       <!-- Star Inversión card -->
        <v-col cols="12" lg="4">
            <v-card>
                <v-card-text class="pa-5">
                    <span class="lstick"></span>
                    <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                    >
                    Balance de fondos
                    </h3>
                        <div class="card-body">
                            <div class="d-flex no-block align-items-center">
                                <div>
                                <h2 class="fs-8 text-gray mb-1">${{depositBalance.currentBalance}}</h2>
                                <span class="text-gray op-5">Fondos disponibles</span>
                                </div>
                                <div class="ms-auto">
                                    <span class="text-white display-6">
                                        <v-icon large color="white" class="card_icon">mdi-currency-usd</v-icon>
                                    </span>
                                </div>
                            </div>
                            <!-- <v-btn class="w-100 btn-rounded btn-light-success text-success">Añadir fondos</v-btn> -->
                            <div class="pt-9 pb-2">
                                <button type="button" class="btn btn-rounded btn-sm btn-success w-100" @click="$router.push('/pages/depositos')"> Añadir fondos </button>
                            </div>
                            <div class="row mt-4">
                                <div class="col-6 d-flex no-block align-items-center pl-1 pb-0">
                                    <v-icon large color="gray">mdi-cash-fast</v-icon>
                                    <div class="mr-auto pl-2">
                                        <h5 class="text-gray mb-0">{{depositBalance.totDeposits}}</h5>
                                        <h6 class="text-muted subtitle-2 mb-0">Cant. depositos</h6>
                                    </div>
                                </div>
                                <div class="col-6 d-flex no-block align-items-center pb-0">
                                    <v-icon large color="gray" class="ms-auto">mdi-cash-multiple</v-icon>
                                    <div class="pl-2">
                                        <h5 class="text-gray mb-0">${{depositBalance.totAmount}}</h5>
                                        <h6 class="text-muted subtitle-2 mb-0">Total depositado</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                </v-card-text>
            </v-card>
        </v-col>
       <!-- End Inversión card -->
       <!-- Star Retiros card -->
        <v-col cols="12" lg="4">
            <v-card>
                <v-card-text class="pa-5">
                    <span class="lstick"></span>
                    <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                    >
                    Saldo para retiros
                    </h3>
                        <div class="card-body">
                            <div class="d-flex no-block align-items-center">
                                <div>
                                <h2 class="fs-8 text-gray mb-1">${{withdrawBalance.currentBalance}}</h2>
                                <span class="text-gray op-5">Disponible para retiros</span>
                                </div>
                                <div class="ms-auto">
                                    <span class="text-white display-6">
                                        <v-icon large color="white" class="card_icon">mdi-currency-usd</v-icon>
                                    </span>
                                </div>
                            </div>
                            <!-- <v-btn class="w-100 btn-rounded btn-light-success text-success">Añadir fondos</v-btn> -->
                            <div class="pt-9 pb-2">
                                <button type="button" class="btn btn-rounded btn-sm btn-warning w-100" @click="$router.push('/pages/retiros')"> Retirar fondos </button>
                            </div>
                            <div class="row mt-4">
                                <div class="col-6 d-flex no-block align-items-center pl-1 pb-0">
                                    <v-icon large color="gray">mdi-account-cash</v-icon>
                                    <div class="mr-auto pl-2">
                                        <h5 class="text-gray mb-0">{{withdrawBalance.totWithdrawals}}</h5>
                                        <h6 class="text-muted subtitle-2 mb-0">Cant. de retiros</h6>
                                    </div>
                                </div>
                                <div class="col-6 d-flex no-block align-items-center pb-0">
                                    <v-icon large color="gray" class="ms-auto">mdi-cash-multiple</v-icon>
                                    <div class="pl-2">
                                        <h5 class="text-gray mb-0">${{withdrawBalance.totAmount}}</h5>
                                        <h6 class="text-muted subtitle-2 mb-0">Total Retirado</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                </v-card-text>
            </v-card>
        </v-col>
       <!-- End Retiros card -->
       <!-- Star Referidos card -->
        <v-col cols="12" lg="4">
            <v-card>
                <v-card-text class="pa-5">
                    <span class="lstick"></span>
                    <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                    >
                    Programa de referidos
                    </h3>
                    <div class="card-body">
                        <div class="d-flex no-block align-items-center">
                            <div>
                            <h2 class="fs-8 text-gray mb-1">${{referBalance.currentBalance}}</h2>
                            <span class="text-gray op-5">Acumulado por afiliados</span>
                            </div>
                            <div class="ms-auto">
                                <span class="text-white display-6">
                                    <v-icon large color="white" class="card_icon">mdi-currency-usd</v-icon>
                                </span>
                            </div>
                        </div>
                        <!-- <v-btn class="w-100 btn-rounded btn-light-success text-success">Añadir fondos</v-btn> -->
                        <div class="pt-9 pb-2">
                            <button type="button" class="btn btn-rounded btn-sm btn-info w-100" @click="$router.push('/pages/referidos')"> Mis socios </button>
                        </div>
                        <div class="row mt-4">
                            <div class="col-6 d-flex no-block align-items-center pb-0">
                                <v-icon large color="gray">mdi-account-group</v-icon>
                                <div class="mr-auto pl-2">
                                    <h5 class="text-gray mb-0">{{referBalance.totRefers}}</h5>
                                    <h6 class="text-muted subtitle-2 mb-0">Total de socios</h6>
                                </div>
                            </div>
                            <div class="col-6 d-flex no-block align-items-center pb-0">
                                <v-icon large color="gray" class="ms-auto">mdi-link-variant</v-icon>
                                <div class="pl-1">
                                    <h5 class="text-gray mb-0">{{referBalance.totLinks}}</h5>
                                    <h6 class="text-muted subtitle-2 mb-0">Clicks a mi link</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
       <!-- End Referidos card -->
       <!-- Star Portafolio card -->
        <v-col cols="12" lg="4">
            <v-card>
                <v-card-text class="pa-5">
                    <span class="lstick"></span>
                    <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                    >
                    Portafolio de inversión
                    </h3>
                        <div class="card-body">
                            <div class="d-flex no-block align-items-center">
                                <div>
                                <h2 class="fs-8 text-gray mb-0">${{investBalance.currentBalance}}</h2>
                                <span class="text-gray op-5">Monto por inversiones activas</span>
                                </div>
                                <div class="ms-auto">
                                    <span class="text-white display-6">
                                        <v-icon large color="white" class="card_icon">mdi-currency-usd</v-icon>
                                    </span>
                                </div>
                            </div>
                            <div class="pt-9 pb-2">
                                <button type="button" class="btn btn-rounded btn-sm btn-success w-100" @click="$router.push('/pages/invertir')"> Invertir </button>
                            </div>
                            <div class="row mt-4">
                                <div class="col-6 d-flex no-block align-items-center pb-0">
                                    <v-icon large color="gray">mdi-file-chart-outline</v-icon>
                                    <div class="mr-auto pl-2">
                                        <h5 class="text-gray mb-0">{{investBalance.cantPlanes}}</h5>
                                        <h6 class="text-muted subtitle-2 mb-0">Planes de inversión</h6>
                                    </div>
                                </div>
                                <div class="col-6 d-flex no-block align-items-center pb-0">
                                    <v-icon large color="gray" class="ms-auto">mdi-cash-multiple</v-icon>
                                    <div class="pl-1">
                                        <h5 class="text-gray mb-0">${{investBalance.totAmount}}</h5>
                                        <h6 class="text-muted subtitle-2 mb-0">Total invertido</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                </v-card-text>
            </v-card>
        </v-col>
       <!-- End Referidos card -->
       
      <v-col cols="12" lg="8">
        <v-card>
          <v-card-text class="pa-5">
            <div class="d-sm-flex align-start">
              <div>
                <span class="lstick"></span>
                <h3
                  class="title blue-grey--text text--darken-2 font-weight-regular"
                >
                  Grafica de inversiones
                </h3>
              </div>
              <div class="ml-auto">
                <div style="width: 150px; height: 40px;">
                  <v-select
                    class="pt-0 mt-0"
                    v-model="select"
                    :items="monthitems"
                    item-text="state"
                    item-value="abbr"
                    label="Select"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="mt-0">
                <GraficaFondos
                :height= 94
                />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
       <!-- Start Planes card -->
        <v-col cols="12" lg="12">
            <v-card>
                <v-card-text class="pa-5">
                    <span class="lstick"></span>
                    <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                    >
                    Planes de inversión
                    </h3>
                        <div class="card-body">
                            <Pricing />
                        </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>


<script>
import axios from 'axios'
import Pricing from '@/components/mainComponents/pricing.vue'
import GraficaFondos from '@/components/mainComponents/GraficaFondos.vue'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

const gradients = [
  ["#745af2"],
  ["#745af2"],
  ["#745af2", "#745af2", "#745af2"],
  ["#745af2", "#745af2"],
  ["#745af2", "#745af2", "#745af2"],
  ["#745af2", "#745af2", "#745af2"],
];

const gradients2 = [
  ["#398bf7"],
  ["#398bf7"],
  ["#398bf7", "#398bf7", "#398bf7"],
  ["#398bf7", "#398bf7"],
  ["#398bf7", "#398bf7", "#398bf7"],
  ["#398bf7", "#398bf7", "#398bf7"],
];

export default {
  name: "BasicDashboard",
  components: {
    Pricing,
    GraficaFondos,
    sesionExpiredDialog
  },
  data: () => ({
    usrToken: '',
    usrData: {},
    dialog: false,
    select: { state: "January 2020", abbr: "FL" },
    monthitems: [
      { state: "January 2020", abbr: "FL" },
      { state: "February 2020", abbr: "GA" },
      { state: "March 2020", abbr: "NE" },
      { state: "April 2020", abbr: "CA" },
    ],
    fill: true,
    gradient: gradients[4],
    gradients,
    padding: 8,
    radius: 4,
    value: [1, 2, 5, 9, 5, 10, 3, 5, 7, 4, 2],
    width: 5,

    fill2: true,
    gradient2: gradients2[4],
    gradients2,
    radius2: 0,
    padding2: 0,
    value2: [0, 150, 110, 240, 200, 200, 300, 200, 380, 300, 400, 380],
    width2: 1,
    task: null,
    selected: [2],
    href() {
      return undefined;
    },
    depositBalance: {
        currentBalance: null,
        totDeposits: null,
        totAmount: null
    },
    withdrawBalance: {
        currentBalance: null,
        totWithdrawals: null,
        totAmount: null
    },
    referBalance: {
        currentBalance: null,
        totRefers: null,
        totLinks: null
    },
    investBalance: {
        currentBalance: null,
        cantPlanes: null,
        totAmount: null
    }
  }),
  
  computed: {},

  methods: {},

  async created () {
    //console.log('Enviroment: ', process.env)
    //console.log('Revisar Inicio de Sesión Resp: ')
    
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    this.usrToken = localStorage.getItem("usrToken")
    //Obtener los datos del Balance de Fondos
    axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/balance`,
        headers: { 
            "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken,
            //"Accept": "application/json"
        }
    })
    .then(async response => {
        //this.loading = false
        let respBalance = response.data
        //console.log('balance...', respBalance);
        if (!respBalance.success){
            console.error('Balance Error: ', respBalance)
        }
        else {
            this.depositBalance = {
                currentBalance: respBalance.data.balance.toLocaleString("es-VE"),
                totDeposits: respBalance.data.cant_depositos.toLocaleString("es-VE"),
                totAmount: respBalance.data.depositos.toLocaleString("es-VE")
            }
        }
    })
    .catch(error => {
        console.error('1 Balance Error: ', error.response)
        this.loading = false;
        //let message = !error.response ? error.message : error.response.data.msg;
        if(error.response && error.response.statusText == "Unauthorized"){
            this.dialog = true
        }
    })
    
    //Obtener los datos del Saldo Para Retiro
    axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/balance_d`,
        headers: { 
            "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken,
            //"Accept": "application/json"
        }
    })
    .then(async response => {
        //this.loading = false
        let respBalance_d = response.data
        //console.log('Withdrawals...', respBalance_d);
        if (!respBalance_d.success){
            console.error('Withdrawals Error: ', respBalance_d)
        }
        else {
            this.withdrawBalance = {
                currentBalance: respBalance_d.data.balance_d.toLocaleString("es-VE"),
                totWithdrawals: respBalance_d.data.cant_reclamados.toLocaleString("es-VE"),
                totAmount: respBalance_d.data.reclamados.toLocaleString("es-VE")
            }
        }
    })
    .catch(error => {
        console.error('Withdrawals Error: ', error)
        this.loading = false;
        //let message = !error.response ? error.message : error.response.data.msg;
        if(error.response && error.response.statusText == "Unauthorized"){
            this.dialog = true
        }
    })

    //Obtener los datos del Balance de referidos
    axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/balance_r`,
        headers: { 
            "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken,
            //"Accept": "application/json"
        }
    })
    .then(async response => {
        //this.loading = false
        let respBalance_r = response.data
        //console.log('Withdrawals...', respBalance_d);
        if (!respBalance_r.success){
            console.error('Withdrawals Error: ', respBalance_r)
        }
        else {
            this.referBalance = {
                currentBalance: respBalance_r.data.balance.toLocaleString("es-VE"),
                totRefers: respBalance_r.data.total_socios.toLocaleString("es-VE"),
                totLinks: '' //respBalance_r.data.reclamados.toLocaleString("es-VE")
            }
        }
    })
    .catch(error => {
        console.error('Withdrawals Error: ', error)
        this.loading = false;
        //let message = !error.response ? error.message : error.response.data.msg;
        if(error.response && error.response.statusText == "Unauthorized"){
            this.dialog = true
        }
    })

    //Obtener los datos del Balance de Inversión
    axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/balance_i`,
        headers: { 
            "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken,
            //"Accept": "application/json"
        }
    })
    .then(async response => {
        //this.loading = false
        let respBalance = response.data
        //console.log('balance...', respBalance);
        if (!respBalance.success){
            console.error('Balance Error: ', respBalance)
        }
        else {
            this.investBalance = {
                currentBalance: Number(respBalance.data.balance_i).toLocaleString("es-VE"),
                cantPlanes: Number(respBalance.data.cant_planes).toLocaleString("es-VE"),
                totAmount: !respBalance.data.depositos ? 0 : Number(respBalance.data.depositos).toLocaleString("es-VE")
            }
        }
    })
    .catch(error => {
        console.error('Balance Error: ', error)
        this.loading = false;
        //let message = !error.response ? error.message : error.response.data.msg;
        if(error.response && error.response.statusText == "Unauthorized"){
            console.log('***Unauthorized***')
            this.dialog = true
        }
    })
  },
  async mounted () {
    //console.log('Dashboard Mounted')
  }
};
</script>
<style>
.border-success {
    border-color: #06d79c!important;
}
.border-start {
    border-left: 1px solid #e9edf2!important;
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(6,215,156,var(--bs-text-opacity))!important;
}
.text-warning {
    --bs-text-opacity: 1;
    color: rgba(255,178,43,var(--bs-text-opacity))!important;
    color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important;
}
/* .text-info {
    --bs-text-opacity: 1;
    color: rgba(57,139,247,var(--bs-text-opacity))!important;
    color: rgba(var(--bs-info-rgb),var(--bs-text-opacity))!important;
} */

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem 0 0 0;
}
.card_icon {
    -ms-flex-item-align: start;
    font-size: 32px;
    height: 48px;
    width: 48px;
    background-color: #00ccc0 !important;
    align-self: flex-start;
    border-radius: 50%;
    min-width: 24px;
    position: relative;
}
.lstick {
    width: 2px;
    background: #00ccc0;
    height: 30px;
    margin-left: -20px;
    margin-right: 18px;
    position: absolute;
}

/*btn classes */
.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}
.btn-primary, .btn-info, .btn-default, .btn-warning, .btn-success, .btn-danger, .btn-outline-primary:hover, .btn-check:checked+.btn-outline-primary, .btn-check:checked+.btn-outline-warning, .btn-check:checked+.btn-outline-success, .btn-check:checked+.btn-outline-danger, .btn-outline-warning:hover, .btn-outline-danger:hover, .btn-outline-info:hover, .btn-outline-success:hover, .btn-primary.active, .btn-info.active, .btn-info:focus, .btn-info:disabled, .btn-success:disabled, .btn-primary:disabled, .btn-danger.active, .btn-success.active, .btn-success:focus, .btn-danger:focus, .btn-primary:focus, .show>.btn-danger.dropdown-toggle, .show>.btn-primary.dropdown-toggle, .show>.btn-info.dropdown-toggle, .show>.btn-success.dropdown-toggle, .show>.btn-warning.dropdown-toggle {
    color: #fff;
}

.btn-success:hover{
    background-color: #e8fdeb;
    border-color: rgba(6,215,156, 1);
    color:rgba(6,215,156, 1)!important
}

.btn-light-success {
    color: #000;
    background-color: #e8fdeb;
    border-color: #e8fdeb;
}
.btn-light-success, .btn-outline-light-success {
    box-shadow: 0 2px 2px #e8fdeb0d;
}
.btn-light-success:hover{background-color:#06d79c;border-color:#06d79c;color:#fff!important}
.btn-light-success:disabled,.btn-light-success.disabled{color:#000;background-color:#e8fdeb;border-color:#e8fdeb}
.btn-light-success:focus{color:#000;background-color:#ebfdee;border-color:#eafded;box-shadow:0 0 0 .25rem #c5d7c880}

.btn-warning:hover {
    color: rgba(255,178,43, 1)!important;
    background-color: #fff8ec;
    border-color: #fb8c00;
}

.btn-light-warning {
    color: #000;
    background-color: #fff8ec;
    border-color: #fff8ec;
}
.btn-light-warning, .btn-outline-light-warning {
    box-shadow: 0 2px 2px #fff8ec0d;
}
.btn-light-warning:hover{background-color:#ffb22b;border-color:#ffb22b;color:#fff!important}
.btn-check:focus+.btn-light-warning,.btn-light-warning:focus{color:#000;background-color:#fff9ef;border-color:#fff9ee;box-shadow:0 0 0 .25rem #d9d3c980}
.btn-check:checked+.btn-light-warning,.btn-check:active+.btn-light-warning,.btn-light-warning:active,.btn-light-warning.active,.show>.btn-light-warning.dropdown-toggle{color:#000;background-color:#fff9f0;border-color:#fff9ee}
.btn-check:checked+.btn-light-warning:focus,.btn-check:active+.btn-light-warning:focus,.btn-light-warning:active:focus,.btn-light-warning.active:focus,.show>.btn-light-warning.dropdown-toggle:focus{box-shadow:0 0 0 .25rem #d9d3c980}
.btn-light-warning:disabled,.btn-light-warning.disabled{color:#000;background-color:#fff8ec;border-color:#fff8ec}

.btn-info:hover {
    color: #1e88e5 !important;
    background-color: #cfecfe;
    border-color: #cfecfe;
}
</style>